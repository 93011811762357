import React from 'react';
import { Fonts } from '../../components/text/text';

import './splash.css';

export default class Splash extends React.Component {
  render() {
    return <div className="splash">
      <div className={`splash__title ${Fonts.Fancy}`}>Bryggdagboken</div>
    </div>
  }
}