import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';

import App from './app/app';

import './index.css';

const firebaseConfig = {
  apiKey: "AIzaSyB-sWx0Q_5YQJYVCG8IIiaSUdCrXtq4QB4",
  authDomain: "bryggdagboken.firebaseapp.com",
  projectId: "bryggdagboken",
  storageBucket: "bryggdagboken.appspot.com",
  messagingSenderId: "335793956356",
  appId: "1:335793956356:web:67969b9830f278ac1ef751",
  measurementId: "G-5R1JJP0C65"
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
