import React from 'react';

import Splash from './pages/splash/splash';

import './app.css';

class App extends React.Component {
  render() {
    return <Splash />
  } 
}

export default App;
